import React from 'react';
import './Logo.css';

export function Logo() {
    return (
        <div className='logo'>
            <span>fractal</span>
            <span>castle</span>
        </div>
    )
}
